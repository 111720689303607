<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <l-control position="topleft">
    <div class="leaflet-control-maplinks">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        :collapse="true"
        :collapse-transition="false"
        :show-timeout="0"
        @open="onMenuHover"
        popper-class="el-maps-menu"
        style="
          --el-menu-icon-width: 30px;
          --el-menu-item-height: 30px;
          --el-menu-base-level-padding: 0 10px 0 8px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border: none;
        "
      >
        <el-sub-menu index="1">
          <template #title>
            <el-icon :size="18"><i style="color: #7f7f7f" class="fa fa-layer-group"></i></el-icon>
            <span>Внешние карты</span>
          </template>
          <el-menu-item-group style="--el-menu-item-height: 25px; --el-menu-level: 25px">
            <template #title> <span class="menu-title">Внешние карты</span> </template>
            <el-menu-item v-for="maplink in externalMapLinks" :key="maplink.title">
              <a class="maplink" :href="maplink.href" target="_blank">{{ maplink.title }}</a>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </el-menu>
    </div>
  </l-control>

  <div></div>
</template>

<script>
import { LControl } from '@vue-leaflet/vue-leaflet';
import { ref } from 'vue';
import geolinks from '@/plugins/geolinks';

export default {
  components: {
    LControl,
  },
  inject: {
    getLeafletMap: { default: ref(false) },
    mapOptions: { default: {} },
  },
  data() {
    return {
      dialogVisible: false,
      selectedDate: null,
      dialogTableVisible: false,
      value2: ref(''),
      externalMapLinks: [],
    };
  },
  computed: {
    myMap() {
      return this.getLeafletMap();
    },
  },
  mounted() {
    this.updateMapLinks();
    // this.groupMaps();
  },
  methods: {
    updateMapLinks() {
      const curCenter = this.myMap.getCenter();
      const curZoom = this.myMap.getZoom();
      this.externalMapLinks = geolinks.getExernalLinks2(
        curCenter,
        curZoom,
        this.mapOptions.showAdditionalMaps,
      );
    },
    groupMaps() {
      this.$nextTick(() => {
        document.querySelectorAll('.maplink').forEach((label) => {
          const mapsList = ['Карта Bing', 'OldMaps Online'];
          console.log(label.textContent.trim());
          if (mapsList.includes(label.textContent.trim())) {
            // eslint-disable-next-line no-param-reassign
            label.style.marginBottom = '10px'; // Set your desired margin-bottom value
          }
        });
      });
    },
    onMenuHover() {
      this.updateMapLinks();
    },
  },
};
</script>

<style scoped>
/* .el-menu-item:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
} */
.el-menu-item {
  line-height: 25px;
  height: 25px;
  font-size: 13px;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.menu-title {
  font-size: 16px;
  font-weight: bold;
}
a.maplink {
  size: 13px;
  color: black;
  text-decoration: none;
}
a.maplink:hover {
  text-decoration: underline;
}

.leaflet-control-maplinks {
  /* left: -10px; */
  /* margin-left: 2px; */
}
.leaflet-control {
  border: 2px solid rgba(0, 0, 0, 0.2);
}
@media (max-height: 500px) {
  .leaflet-control {
    border-left: none;
    border-top: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
    border-bottom: 1px solid #a3a3a3;
  }
}

.leaflet-control-maplinks > ul {
  border: 2px solid rgba(0, 0, 0, 0.2);
  /* border: none !important; */
}
</style>
