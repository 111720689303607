/* eslint-disable no-empty-pattern */
import axios from '@/plugins/axios';
import mutations from '@/store/mutations';
import myhelper from '@/plugins/myhelper';
import JsonStableStringify from 'json-stable-stringify';
import { ElNotification } from 'element-plus';
import { toRaw } from 'vue';
import localforage from 'localforage';

function serializeResponse(places) {
  return places.reduce((acc, place) => {
    const placenew = place;
    placenew.zoom = place.zoom.toLowerCase();
    acc[place.place_id] = placenew;
    return acc;
  }, {});
}

function propFilter(queryString) {
  return (place) =>
    // console.log(place);
    {
      const title = myhelper.getPlaceFullTitle(place, true);
      const normalizedTitle = myhelper.removeDiacritics(title);
      const normalizedQuery = myhelper.removeDiacritics(queryString);
      // remove diacritics from string

      return normalizedTitle.toLowerCase().indexOf(normalizedQuery.toLowerCase()) >= 0;
    };
}

const { PLACES, UPDATE_PLACE, DELETE_PLACE, ADD_PLACE } = mutations;

const placesStore = {
  namespaced: true,
  state: {
    places: {},
  },
  getters: {
    placesList: ({ places }) => Object.values(places).sort((a, b) => a.seqid - b.seqid),
    filledPlacesList: ({}, getters) =>
      getters.placesList.filter((place) => place.latitude && place.latitude), // console.log(places);

    filledCAEPlacesList: ({}, getters) =>
      getters.filledPlacesList.filter((place) => place.visited_on === 'cae'), // console.log(places);

    placesListByVisit:
      ({}, getters) =>
      (visitedOn) =>
        getters.filledPlacesList.filter((place) => place.visited_on === visitedOn), // console.log(places);

    placeByID:
      ({ places }) =>
      (placeId) =>
        places[placeId],
    placeBySeq:
      ({ places }) =>
      (SeqId) =>
        Object.values(places).filter((x) => x.seqid === SeqId)[0],
    // eslint-disable-next-line no-unused-vars
    isInitialized: ({ places }, _getters, rootState, rootGetters) => {
      const images = Object.values(rootState.images.images);
      return (
        Object.keys(places).length > 0 &&
        Object.keys(images).length > 0 &&
        rootGetters['books/isBooksInitialized']
      );
    },
    // eslint-disable-next-line no-unused-vars
    mapPathRoute: ({ places }, getters) => {
      const result = getters.filledPlacesList.reduce((acc, place) => {
        // console.log(place);
        // if (place.latitude !== '' && place.latitude !== 0) {
        acc.push([place.latitude, place.longitude]);
        try {
          const rj = JSON.parse(place.routejson);
          acc.push(...rj);
        } catch (error) {
          console.warn(error);
          console.warn(place.routejson);
          console.warn(place);
        }

        // }
        // acc.push(placeId);
        return acc;
      }, []);
      // console.log(result);
      return result;
    },
    getFilledPrevPlace:
      ({}, getters) =>
      (seqid) =>
        getters.filledPlacesList.filter((place) => place.seqid < seqid).reverse()[0], //

    getFilledNextPlace:
      ({}, getters) =>
      (seqid) =>
        getters.filledPlacesList.filter((place) => place.seqid > seqid)[0], //

    getPrevPlace:
      ({}, getters) =>
      (seqid) =>
        getters.placesList.filter((place) => place.seqid < seqid).reverse()[0], //

    getNextPlace:
      ({}, getters) =>
      (seqid) =>
        getters.placesList.filter((place) => place.seqid > seqid)[0], //

    // check that date and enddate are not equal
    getPrevStopPlace:
      ({}, getters) =>
      (seqid) =>
        getters.placesList
          .filter((place) => place.seqid < seqid && place.date !== place.enddate)
          .reverse()[0], //

    // mapMarkersList: ({ places }) => {
    //   const result = Object.values(places).reduce((acc, place) => {
    //     // console.log(placeId);
    //     if ((Object.keys(acc).length <= 10) && (place.latitude !== '')) { acc.push(place); }
    //     // acc.push(placeId);
    //     return acc;
    //   }, []);
    //   // console.log(result);
    //   return result;
    // },

    mapMarkersList: ({ places }, _getters, rootState) => {
      // get images where place_id is not empty
      const imagesList = Object.values(rootState.images.images).filter(
        (image) => !!image.place_id && image.image_enabled,
      );
      // reduce imageList to get hash with place_id as key and count of images
      const imagesCount = imagesList.reduce((acc, image) => {
        acc[image.place_id] = acc[image.place_id] || 0;
        acc[image.place_id] += 1;
        return acc;
      }, {});
      const result = Object.values(places).reduce((acc, place) => {
        const { zoom } = place;
        if (zoom === '') {
          return acc;
        }
        acc[zoom] = acc[zoom] || [];
        if (place.latitude === '' || place.longitude === '') {
          return acc;
        }
        // add to place count of images by plac_id using imagesCount and push ut to acc[zoom]
        acc[zoom].push({ ...place, imgcount: imagesCount[place.place_id] || 0 });
        return acc;
      }, {});
      return result;
    },

    // this methos uses minzoom instead of zoom
    mapMarkersList2:
      ({}, _getters, rootState) =>
      (visitedOn) => {
        // get images where place_id is not empty
        const imagesList = Object.values(rootState.images.images).filter(
          (image) => !!image.place_id && image.image_enabled,
        );
        // reduce imageList to get hash with place_id as key and count of images
        const imagesCount = imagesList.reduce((acc, image) => {
          acc[image.place_id] = acc[image.place_id] || 0;
          acc[image.place_id] += 1;
          return acc;
        }, {});
        const result = _getters.placesListByVisit(visitedOn).reduce((acc, place) => {
          const { minzoom } = place;
          if (minzoom === '' || place.latitude === '' || place.longitude === '') {
            return acc;
          }
          acc[minzoom] = acc[minzoom] || [];
          // add to place count of images by plac_id using imagesCount and push ut to acc[zoom]
          acc[minzoom].push({ ...place, imgcount: imagesCount[place.place_id] || 0 });
          return acc;
        }, {});
        // console.log(result);
        return result;
      },
    getPlacesOptions2:
      ({}, getters) =>
      () => {
        const options = getters.filledPlacesList.map((place) => {
          let placedate = '';
          if (place.date) {
            placedate = place.date;
            if (place.enddate && place.date !== place.enddate) {
              placedate = `${placedate} - ${place.enddate}`;
            }
          }
          return {
            label: `${place.place_id}: ${place.title_ru} (${placedate})`,
            value: place.place_id,
          };
        });
        return [{ label: '<Not Selected>', value: 0 }, ...options];
      },

    getPlacesListFromRange:
      ({}, getters) =>
      (fromPlaceId, toPlaceId) => {
        const fromPlace = getters.placeByID(fromPlaceId);
        const toPlace = getters.placeByID(toPlaceId);

        return getters.filledPlacesList
          .filter((place) => place.seqid >= fromPlace.seqid && place.seqid <= toPlace.seqid)
          .map((place) => ({ ...toRaw(place) }));
      },

    searchByTitle:
      ({}, getters) =>
      (queryString, cb) => {
        const results =
          queryString.length > 1 ? getters.placesList.filter(propFilter(queryString)) : [];
        // call callback function to return suggestion objects
        cb(results);
      },
  },
  mutations: {
    [PLACES](state, value) {
      state.places = value;
    },
    [UPDATE_PLACE](state, { id, value }) {
      // console.log(UPDATE_PLACE);
      // console.log(id);
      // console.log(value);
      state.places[id] = value;
    },
    [DELETE_PLACE](state, id) {
      console.log(`${DELETE_PLACE} ${id}`);
      // this.$delete(state.places, id);
      delete state.places[id.toString()];
      // console.log(state);
      // state.places[id] = value;
    },
    [ADD_PLACE](state, place) {
      state.places[place.place_id] = place;
      // console.log(`${ADD_PLACE} ${place.place_id}`);
      // console.log(place);
    },
  },
  actions: {
    initPlaceStore: {
      handler({ dispatch }) {
        dispatch('fetchPlaces');
        // console.log('fetchPlaces');
      },
      root: true,
    },
    async fetchPlaces({ commit }) {
      const api = '/places/';
      try {
        const response = await axios.get('/places/', { timeout: 15000 });
        const places = serializeResponse(response.data);
        commit(PLACES, places);
        localStorage.removeItem(api);
        await localforage.setItem(api, places);
      } catch (err) {
        try {
          const cached = await localforage.getItem(api);
          if (cached) {
            console.log(err);
            console.error(`Using cached places`, err);
            myhelper.remoteLog('init_places_warning_cached', err);
            commit(PLACES, cached);
            this.$caemap.loadingcached = true;
          } else {
            console.log(err);
            myhelper.remoteLog('init_places_error', err);
            ElNotification.error({
              title: 'Ошибка инициализации',
              message: `Ошибка получения данных по API: ${err.message}`,
              duration: 50000,
            });
          }
        } catch (storageError) {
          console.error('Error accessing local storage:', storageError);
          ElNotification.error({
            title: 'Storage Error',
            message: `Failed to access local storage: ${storageError.message}`,
            duration: 50000,
          });
        }
      }
    },
    // eslint-disable-next-line
    deletePlace({ commit, state }, placeId) {
      return new Promise((resolve, reject) => {
        // console.log('deleting Place ', placeId);
        if (placeId in state.places) {
          axios
            .delete(`/places/${placeId}`)
            .then((response) => {
              // console.log(response.data);
              commit(DELETE_PLACE, placeId);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.message);
            });
        }
      });
    },
    // eslint-disable-next-line
    updatePlaceSeqid({ commit, dispatch, state, getters }, { placeId, newSeqID }) {
      const place = getters.placeByID(placeId);
      // console.log(place);

      if (place.seqid !== newSeqID) {
        // console.log('updatePlaceSeqid ', placeId, place.seqid, newSeqID);
        place.seqid = newSeqID;
        // console.log(place);
        commit(UPDATE_PLACE, { id: placeId, value: place });
        axios.put(`/places/${placeId}`, { ...place }).then((response) => {
          console.log(response.data);
          console.log(place);
        });
      }

      // // console.log(place);
      // if (placeId in state.places) {
      //   const updatedPlace = { ...state.places[placeId], ...place };
      //   // console.log(JSON.stringify(Object.keys(state.places[placeId])));
      //   console.log(updatedPlace);
      //   commit(UPDATE_PLACE, { id: placeId, value: updatedPlace });
      //   axios.put(`/places/${placeId}`, { ...updatedPlace })
      //     .then((response) => console.log(response.data));
      //   // updatedPlace
      // }
    },
    updatePlaceSeqIds({ dispatch }, { updateData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/places_change/seqids`, [...updateData])
          .then((response) => {
            dispatch('fetchPlaces');
            myhelper.ElSuccess('Bulk places SeqID updating has been completed');
            resolve(response.data);
          })
          .catch((error) => {
            myhelper.ElError(`Places seqid update error: ${error}`);
            reject(error);
          });
      });
    },

    // eslint-disable-next-line
    updatePlace({ commit, dispatch, state }, { placeId, place }) {
      return new Promise((resolve, reject) => {
        // console.log('updating Place ', placeId);
        // console.log(place);
        if (placeId in state.places) {
          const updatedPlace = { ...state.places[placeId], ...place };
          // we need to pass pydantic model None instead of empty string
          if (updatedPlace.enddate === '') updatedPlace.enddate = null;
          if (updatedPlace.date === '') updatedPlace.date = null;
          // console.log(JSON.stringify(Object.keys(state.places[placeId])));
          axios
            .put(`/places/${placeId}`, { ...updatedPlace })
            .then((response) => {
              // console.log(response.data);
              // return JsonStableStringify(this.placeForm) !== JsonStableStringify(this.editPlace);
              if (JsonStableStringify(updatedPlace) !== JsonStableStringify(response.data)) {
                console.log('Response from server:');
                console.log(response.data);
                console.log(JsonStableStringify(updatedPlace));
                console.log(JsonStableStringify(response.data));
                Object.entries(updatedPlace).map((entry) => {
                  const key = entry[0];
                  const value = entry[1];
                  if (value !== response.data[key]) {
                    console.log(key, value, response.data[key]);
                  }

                  return undefined;
                });
                ElNotification.error({
                  title: 'Save error',
                  message: `Error while updating place "${updatedPlace.title_ru}"\nSome datas weren't saved.\nMore details in the console`,
                  duration: 50000,
                });
                reject();
              } else {
                ElNotification({
                  title: 'success',
                  message: 'Updated place successfully',
                  type: 'success',
                  duration: 500,
                });
                resolve();
              }
              commit(UPDATE_PLACE, { id: placeId, value: response.data });
            })
            .catch((error) => {
              myhelper.ElError(
                `Error while updating place "${updatedPlace.title_ru}"\n${error.message}`,
              );
              reject();
            });
          // updatedPlace
        } else {
          reject();
        }
      });
    },
    // eslint-disable-next-line
    async addNewPlace({ commit, dispatch, state }, place) {
      return new Promise((resolve, reject) => {
        // console.log('adding Place to order ');
        // console.log(place);
        axios
          .post(`/places/`, place)
          .then((response) => {
            const createdplace = response.data;
            // console.log(createdplace);
            commit(ADD_PLACE, createdplace);
            resolve(createdplace);
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
  },

  modules: {},
};

export default placesStore;
