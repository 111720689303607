import JsonStableStringify from 'json-stable-stringify';
import { ElNotification, ElMessageBox } from 'element-plus';
import uFuzzy from '@leeoniya/ufuzzy';
import { marked } from 'marked';
import markedFootnote from 'marked-footnote';
import { toRaw } from 'vue';

marked.use(markedFootnote());

function isJsonsEqual(json1, json2, exclude = [], log = false) {
  // console.log(json1, json2);
  // console.log(exclude);
  const clonedJson1 = { ...json1 };
  const clonedJson2 = { ...json2 };
  exclude.forEach((key) => {
    delete clonedJson1[key];
    delete clonedJson2[key];
  });
  const isEqual = JsonStableStringify(clonedJson1) === JsonStableStringify(clonedJson2);
  if (log && !isEqual) {
    console.log('Response and and the same sent data:');
    console.log(JsonStableStringify(clonedJson1));
    console.log(JsonStableStringify(clonedJson2));
    Object.entries(clonedJson1).map((entry) => {
      const key = entry[0];
      const value = entry[1];
      if (value !== clonedJson2[key]) {
        console.error(
          'This key is different: ',
          key,
          ' value1: ',
          value,
          ' value2:',
          clonedJson2[key],
        );
      }
      return undefined;
    });
  }

  return isEqual;
}

function generateFileFromData(data, filename, type) {
  const bb = new Blob([data], { type });
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', window.URL.createObjectURL(bb));
  downloadAnchorNode.setAttribute('download', filename);
  downloadAnchorNode.dataset.downloadurl = [
    type,
    downloadAnchorNode.download,
    downloadAnchorNode.href,
  ].join(':');
  downloadAnchorNode.draggable = true;
  downloadAnchorNode.classList.add('dragout');
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
}

function isWindows() {
  if (/Windows /i.test(navigator.userAgent)) {
    return true;
  }
  return false;
}

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

function ElSuccess(message, duration = 5000) {
  ElNotification({
    title: 'success',
    message,
    type: 'success',
    duration,
  });
}

function ElWarning(message, duration = 5000) {
  ElNotification.warning({
    message,
    'show-close': true,
    duration,
  });
}

function ElError(message, duration = 0) {
  ElNotification.error({
    message,
    'show-close': true,
    duration,
  });
}

// Click COnfirmation function, returning promise. If shiftPressed is true - just resolved promise
async function confirmDialog(confirmtext, shiftPressed = false) {
  return new Promise((resolve, reject) => {
    if (shiftPressed) {
      resolve();
    } else {
      ElMessageBox.confirm(confirmtext, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    }
  });
}

// eslint-disable-next-line no-unused-vars
function FormatDate(parsedDate, dateFormat) {
  if (dateFormat === 'YYYY-MM-DD') {
    return parsedDate.toLocaleString('ru', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
  if (dateFormat === 'YYYY-MM') {
    const r = parsedDate.toLocaleString('ru', {
      year: 'numeric',
      month: 'long',
    });
    return r.charAt(0).toUpperCase() + r.slice(1);
  }
  if (dateFormat === 'YYYY') {
    return parsedDate.toLocaleString('ru', {
      year: 'numeric',
    });
  }
}

function getFullMonthName(parsedDate) {
  const r = parsedDate.toLocaleString('ru', {
    month: 'long',
  });
  // console.log(r);
  return r.charAt(0).toUpperCase() + r.slice(1);
}

// eslint-disable-next-line no-unused-vars
function humanizeDateRange(start, end = '', dateFormat = 'YYYY-MM-DD') {
  if (dateFormat === 'custom') {
    return start;
  }
  // nextDate = new Date(Date.parse(baseDate) + 24 * 60 * 60 * 1000).toJSON().slice(0, 10);
  if (!start) return '';
  const parsedStartDate = new Date(Date.parse(start));
  const parsedEndDate = new Date(Date.parse(end));
  const startDate = FormatDate(parsedStartDate, dateFormat);
  let suffix = dateFormat === 'YYYY' ? ' г.' : '';
  if (!end) return startDate + suffix;

  const endDate = FormatDate(parsedEndDate, dateFormat);
  if (startDate === endDate) {
    return startDate + suffix;
  }

  const startMonth = parsedStartDate.toJSON().slice(0, 7);
  const endMonth = parsedEndDate.toJSON().slice(0, 7);
  if (startMonth === endMonth) {
    return `${parsedStartDate.getDate()} — ${endDate} `;
  }
  if (dateFormat === 'YYYY-MM') {
    return `${getFullMonthName(parsedStartDate)} — ${endDate} `;
  }

  if (parsedStartDate.getYear() === parsedEndDate.getYear()) {
    const curDayMonth = parsedStartDate.toLocaleString('ru', {
      month: 'long',
      day: 'numeric',
    });
    return `${curDayMonth} — ${endDate} `;
  }

  suffix = dateFormat === 'YYYY' ? ' гг.' : '';
  return `${startDate} — ${endDate} ${suffix}`;
}

function humanizeImageDate(data) {
  if (data.datetime_original && !data.year) {
    return humanizeDateRange(data.datetime_original, '', data.date_format);
    // const date = new Date(data.datetime_original);
    // return date.toLocaleDateString('ru', {
    //   day: 'numeric',
    //   month: 'short',
    //   year: 'numeric',
    // });
  }
  return humanizeDateRange(data.year, data.enddate, data.date_format);
}

function DaysBetweenDates(date1, date2) {
  if (!date1 || !date2) {
    return 0;
  }
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
}

function serializeResponse(arr, key) {
  return arr.reduce((acc, el) => {
    acc[el[key]] = el;
    return acc;
  }, {});
}

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16)}`);
}

function debounce(fn, wait = 300) {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
}

function accLevelImages() {
  return {
    // low: require('@/assets/levels/battery-10.png'),
    // medium: require('@/assets/levels/battery-20.png'),
    // high: require('@/assets/levels/battery-40.png'),
    // exact: require('@/assets/levels/battery-50.png'),
    low: require('@/assets/levels/siglevel-10.jpeg'),
    medium: require('@/assets/levels/siglevel-20.jpeg'),
    high: require('@/assets/levels/siglevel-30.jpeg'),
    exact: require('@/assets/levels/siglevel-40.jpeg'),
  };
}

function accLevel2Image(placeAccLevel) {
  // - 1 деление (красный уровень) - координаты места совсем непонятны и требуют уточнения. Точность > 10км
  // - 2 деления (оранжевый) - Есть опорные точки но точность мала - точность 5-10 км
  // - 4 деления (зеленый) - Есть название населенного пункта. Точность 100 метров - 5 км
  // - 5 делений - полный уровень - известно место остановки вплоть до конктретного здания или места. Точность <100 м
  return accLevelImages()[placeAccLevel];
}

function getPlaceTitle(place, showModern = true) {
  const title = place.title_ru.split(';')[0];
  if (!place.title_modern || !showModern) return title;
  const titleModern = place.title_modern.split(';')[0];
  return `${title} (${titleModern})`;
}
function getPlaceAltTitles(place) {
  return place.title_ru.replace(/; /g, ';').split(';').slice(1);
}

function getPlaceFullTitle(place, showModern = true) {
  const title = place.title_ru.replace(/; /g, ';').split(';').join(', ');
  if (!place.title_modern || !showModern) return title;
  const titleModern = place.title_modern;
  return `${title} (${titleModern})`;
}

function removeDiacritics(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

// forSelectedMarker - means this marker clicked and about it is being showed in the sidebar
function tooltipByMarker(marker, { forSelectedMarker = false, showModern = true } = {}) {
  const batteryImage = accLevel2Image(marker.accuracy_level);
  const accLevelIcon = batteryImage ? `<img class="acclevel" src='${batteryImage}' />` : '';
  const placeTitle = getPlaceTitle(marker, showModern);
  let tooltip = `<span class="tooltip-place-title">${placeTitle}</span>${accLevelIcon}`;
  if (forSelectedMarker) {
    tooltip = `<span class="tooltip-place-title selected-place">${placeTitle}</span>`;
  }
  let imgCountTag = '';
  if (marker.imgcount > 0) {
    const imgTag = `<img src='${require('@/assets/imagescnt.png')}' />`;
    const onclickTag = `onClick="onShowImageClick(event,${marker.place_id});"`;
    imgCountTag = ` <a class="imgcount" href="#" ${onclickTag}>${imgTag}${marker.imgcount}</a>`;
  }
  const latlng = `${marker.latitude.toFixed(2)}, ${marker.longitude.toFixed(2)}`;
  tooltip += imgCountTag;
  tooltip += `<span class="tooltip-place-latlng">${latlng}</span>`;
  return tooltip;
}

function safeJSONParse(str, defaultValue = {}) {
  try {
    return JSON.parse(str);
  } catch (error) {
    return defaultValue;
  }
}

function loadCssDynamic(url) {
  const link = document.createElement('link');
  link.href = url;
  link.rel = 'stylesheet';
  link.type = 'text/css';
  document.getElementsByTagName('head')[0].appendChild(link);
}

// log Using GET request by sending to /log.txt?event=EVENT&data=DATA
function remoteLog(event, data) {
  const url = `${process.env.VUE_APP_SERVER_URL}/log.txt?event=${event}&data=${data}`;
  fetch(url, {
    method: 'GET',
  });
}

function getPathFromPlace(place) {
  const path = [[place.latitude, place.longitude]];
  try {
    const rj = JSON.parse(place.routejson);
    path.push(...rj);
  } catch (error) {
    console.warn('getPathFromPlace', error);
    console.warn(place.routejson);
    console.warn(place);
  }
  return path;
}

function getPathDistance(map, path) {
  let distance = 0;
  for (let i = 0; i < path.length - 1; i += 1) {
    distance += map.distance(path[i], path[i + 1]);
  }
  return distance;
}

function getPolyPathFromPlaces(placesList, includeLatest = true) {
  let placesCopy;
  if (!includeLatest) {
    placesCopy = [...placesList];
    placesCopy.slice(-1)[0].routejson = '[]';
  } else {
    placesCopy = placesList;
  }
  return placesCopy.reduce((acc, place) => {
    acc.push(...getPathFromPlace(place));
    return acc;
  }, []);
}

function getAccuracyByLvl(acclvl) {
  switch (acclvl) {
    case 'low':
      // 25 km
      return 50;
    case 'medium':
      // 10 km
      return 25;
    case 'high':
      // 5 km
      return 5;
    case 'exact':
      // 1 km
      return 1;
    default:
      return 0;
  }
}

function getAccuracyOptions() {
  // const options = [];
  // options.push({ value: 'unknown', label: `unknown` });
  // ['low', 'medium', 'high', 'exact'].forEach((lvl) => {
  //   options.push({
  //     value: lvl,
  //     label: `${lvl} (${getAccuracyByLvl(lvl)} km)`,
  //   });
  //   //   { value: 'high', label: `high (${this.getAccuracyByLvl('high')} km)` },
  // });

  return [
    // 5 делений - полный уровень - известно место остановки вплоть до конктретного здания или места. Точность <100 м
    { value: 'exact', label: `exact` },
    // - 4 деления (зеленый) - Есть название населенного пункта. Точность 100 метров - 5 км
    { value: 'high', label: `high (city)` },
    // - 2 деления (оранжевый) - Есть опорные точки но точность мала - точность 5-10 км
    { value: 'medium', label: `medium (5-10km)` },
    // - 1 деление (красный уровень) - координаты места совсем непонятны и требуют уточнения. Точность > 10км
    { value: 'low', label: 'low (>10 km)' },
    // unknown
    { value: 'unknown', label: 'unknown' },
  ];
  // return options;
}

function getAccuracyNameByLvl(acclvl) {
  const r = getAccuracyOptions().filter((opt) => opt.value === acclvl);
  if (r.length === 0) {
    return '';
  }
  return r[0].label;
}

function getCoordFromText(text) {
  const coords = text.match(/(?:^|.*@)([0-9.]+)(?:,|\s)+([0-9.]+)/);
  if (coords) {
    return [Number(coords[1]), Number(coords[2])];
  }
  return null;
}

function copyToClipboard(text) {
  if (!navigator.clipboard) {
    ElError('Clipboard API not available');
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      ElSuccess('Текст был скопирован в буфер обмена', 1000);
    },
    (err) => {
      ElError(`Ошибка копирования текста в буфер обмена: ${err}`);
    },
  );
}

function copyCoordtoClipboard(latitude, longitude) {
  // clipboard workaround here https://stackoverflow.com/a/65996386
  if (!latitude || !longitude) {
    ElError('No coordinates to copy');
    return;
  }
  copyToClipboard(`${latitude}, ${longitude}`);
}

function isTextFieldActive() {
  const activeTag = document.activeElement.tagName.toLowerCase();
  // check if activeTag is textarea or input
  return activeTag === 'textarea' || activeTag === 'input' || activeTag === 'select';
}

async function fetchUserCountry() {
  const defaultCountry = 'US';

  const country = await fetch(`${process.env.VUE_APP_SERVER_URL}/country.js?_=${Date.now()}`)
    .then((res) => res.json())
    .then((location) => {
      if (location.country) {
        return location.country;
      }
      return defaultCountry;
    })
    .catch(() => {
      return defaultCountry;
    });
  return country;
}

function goByEmoji(goBy) {
  if (goBy === 'car') {
    return '🚗';
  }
  if (goBy === 'train') {
    return '🚂';
  }
  if (goBy === 'steamship') {
    return '🚢';
  }
  if (goBy === 'horse') {
    return '🐎';
  }
  if (goBy === 'horsecart') {
    return '🛞';
  }

  return '⁉️';
}

// return hash with icons and tooltips
function goByInfo(goBy) {
  const icons = {
    car: '<i class="fas fa-car"></i>',
    steamship: '<i class="fas fa-ship"></i>',
    train: `<i class="fas fa-subway"></i>`,
    horse: `</i><img class="horse" src="${require('@/assets/icons/horse2.png')}" alt="" />`,
    // horse: `<i class="fas fa-subway"></i><i class="fas fa-ship"></i><i class="fas fa-car"></i><img class="horse" src="${require('@/assets/icons/horse2.png')}" alt="" /><img src="${require('@/assets/icons/horsecart2.png')}" alt="" />`,
    horsecart: `<img class="horsecart" src="${require('@/assets/icons/horsecart2.png')}" alt="" />`,
  };
  const tooltips = {
    car: 'На машине',
    steamship: 'На пароходе',
    train: 'На поезде',
    horse: 'Верхом',
    horsecart: 'На бричке',
  };
  return { icon: icons[goBy] || '', tooltip: tooltips[goBy] || '' };
}

function simplifyLatlngs(L, points, tolerance) {
  function latlngToXy(p) {
    return {
      x: p[1],
      y: p[0],
    };
  }

  // eslint-disable-next-line no-unused-vars
  function xyToLatlng(p) {
    return [p.y, p.x];
  }

  let points1 = points.map(latlngToXy);
  // console.log(points1);
  points1 = L.LineUtil.simplify(points1, tolerance);
  // console.log(points1);
  points1 = points1.map(xyToLatlng);
  return points1;
  // return [];
}

function isResearcher() {
  return (
    localStorage.getItem('authRole') === 'researcher' ||
    localStorage.getItem('authRole') === 'adminmode'
  );
}

function isAdmin() {
  return localStorage.getItem('authRole') === 'adminmode';
  // return this.$store.getters['auth/isAdmin'];
}

function waitForElm(selector) {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      // eslint-disable-next-line no-promise-executor-return
      return resolve(document.querySelector(selector));
    }

    // eslint-disable-next-line no-unused-vars
    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
function uFuzzyInit() {
  const opts = {
    interSplit: "[^A-Za-zА-ЯЁа-яё\\-\\d']+",
    intraSplit: '[a-z][A-Z]|[а-яё][А-ЯЁ]\\-',
    intraBound: '[A-Za-zА-ЯЁа-яё]\\d|\\d[A-Za-zА-ЯЁа-яё]|[a-z][A-Z]|[а-яё][А-ЯЁ]',
    intraChars: "[a-zа-яё\\d'\\-]",
    intraContr: "'[a-z]{1,2}\\b",
    intraMode: 1, // SingleError
  };

  // eslint-disable-next-line new-cap
  return new uFuzzy(opts);
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve({
        // data: arrayBufferToString(e.target.result),
        data: e.target.result,
        filename: file.name,
      });
    };
    // reader.readAsArrayBuffer(file.raw);
    reader.readAsText(file.raw, 'UTF-8');
  });
}
function extractPathFromUrl(url) {
  // Create an anchor element to use the browser's URL parser
  const a = document.createElement('a');
  a.href = url;

  // Return the pathname
  return a.pathname;
}
function markedRenderer() {
  const renderer = new marked.Renderer();
  // get current page uri without query and hash

  renderer.link = (href, title, text) => {
    const curPath = window.location.pathname;
    // get path only from href without query and hash
    const curPathFromHref = extractPathFromUrl(href);
    let linkAttr = 'target="_blank" rel="noopener noreferrer"';
    // make linkAttr empty if href is local
    if ((href.startsWith('/') || href.startsWith('#')) && !href.includes('#m=')) {
      linkAttr = '';
    }
    if (href.includes('#m=') && curPath === curPathFromHref) {
      linkAttr = '';
    }
    // if jpg or png - open in new tab
    if (href.endsWith('.jpg') || href.endsWith('.png')) {
      linkAttr = 'target="_blank" rel="noopener noreferrer"';
    }

    return `<a ${linkAttr} href="${href}">${text}</a>`;
  };
  return renderer;
}

function compiledMarkdown(text) {
  return marked(text, { renderer: markedRenderer() });
}

function isPlaceStayedLong(place) {
  const longStayDays = 5;
  const { date, enddate } = place;
  if (date == null || enddate == null) {
    return false;
  }
  const dateTs = new Date(Date.parse(date));
  const enddateTs = new Date(Date.parse(enddate));
  const stayDays = (enddateTs - dateTs) / 1000 / 60 / 60 / 24;
  return stayDays > longStayDays;
}

// convert date range string to array of every day in this range in format YYYY-MM-DD. if endDate is empty - return array with only startDate
function dateRange2Array(startDate, endDate) {
  const dateArray = [];
  if (!endDate || startDate === endDate) {
    return [startDate];
  }
  const currentDate = new Date(startDate);
  const end = new Date(endDate);
  while (currentDate < end) {
    dateArray.push(new Date(currentDate).toJSON().slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}
function copyBookTextHelper(tag, selectedPlace) {
  let text = '';
  if (tag === 'span') {
    text = `<span data-text4placeid='${selectedPlace}' class='ellipsis-before ellipsis-after'></span>`;
  }
  if (tag === 'p') {
    text = ` data-text4placeid='${selectedPlace}'  class='ellipsis-before ellipsis-after'`;
  }
  if (text) {
    copyToClipboard(text);
  }
}

function createPlacesHashWithDateAsKey(placeList) {
  // console.time('Function #2');
  const placesHash = {};
  placeList.forEach((place) => {
    if (place.date_format !== 'YYYY-MM-DD') {
      return;
    }
    // if (!placesHash[place.date]) {
    //   placesHash[place.date] = [];
    // }
    if (place.date === place.enddate) {
      return;
    }
    if (place.minzoom < 0) {
      return;
    }
    if (!place.enddate) {
      // placesHash[place.date].push(place);
      return;
    }
    dateRange2Array(place.date, place.enddate).forEach((date) => {
      if (date === place.enddate) {
        return;
      }
      // if (!placesHash[date]) {
      //   placesHash[date] = [];
      // }
      // placesHash[date] = myhelper.getPlaceTitle(place, false);
      placesHash[date] = toRaw(place);
    });
  });
  // console.timeEnd('Function #2');
  // for each placeHash hash of hash return only title_ru instead of full result
  // console.log('placesHash', placesHash);
  // this.placesHash = placesHash;
  return placesHash;
}
function isBotUserAgent() {
  const userAgent = navigator.userAgent.toLowerCase();
  const bots = ['yandex', 'prerender', 'google'];
  // const isbot = bots.some((bot) => userAgent.indexOf(bot) !== -1);
  // remoteLog('isBotUserAgent', isbot);
  return bots.some((bot) => userAgent.indexOf(bot) !== -1);
}

export default {
  isJsonsEqual,
  generateFileFromData,
  isMobile,
  isWindows,
  isTouchDevice,
  confirmDialog,
  ElSuccess,
  ElWarning,
  ElError,
  humanizeDateRange,
  humanizeImageDate,
  serializeResponse,
  fixedEncodeURIComponent,
  debounce,
  getPathDistance,
  safeJSONParse,
  getPlaceTitle,
  getPlaceAltTitles,
  getPlaceFullTitle,
  removeDiacritics,
  tooltipByMarker,
  accLevel2Image,
  getPathFromPlace,
  getPolyPathFromPlaces,
  getAccuracyOptions,
  getAccuracyByLvl,
  getAccuracyNameByLvl,
  getCoordFromText,
  copyToClipboard,
  copyCoordtoClipboard,
  isTextFieldActive,
  fetchUserCountry,
  DaysBetweenDates,
  goByEmoji,
  goByInfo,
  simplifyLatlngs,
  isResearcher,
  isAdmin,
  loadCssDynamic,
  remoteLog,
  waitForElm,
  uFuzzyInit,
  readFile,
  compiledMarkdown,
  isPlaceStayedLong,
  dateRange2Array,
  copyBookTextHelper,
  createPlacesHashWithDateAsKey,
  isBotUserAgent,
};
