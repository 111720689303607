<template>
  <div></div>
</template>
<script>
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import 'leaflet.polylinemeasure';
import 'leaflet.fullscreen/Control.FullScreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

import 'leaflet-copy-coordinates-control';
import 'leaflet-copy-coordinates-control/dist/Control.Coordinates.min.css';

import 'leaflet-bookmarks';
import 'leaflet-bookmarks/dist/leaflet.bookmarks.css';
import hotkeys from 'hotkeys-js';
import myhelper from '@/plugins/myhelper';

export default {
  name: 'LeafLetHelpers',
  props: {
    leafletRef: { type: Object, required: true },
  },
  data() {
    return {
      controlsVisible: true,
      fullScreenStatus: false,
    };
  },
  methods: {
    isTextFieldActive: myhelper.isTextFieldActive,
    initBookmarks() {
      this.bookmarksControl = new window.L.Control.Bookmarks({
        position: 'topleft',
      });
      this.bookmarksControl.addTo(this.myMap);
    },
    initFullScreen() {
      this.fullscreenControl = window.L.control.fullscreen({
        position: 'topleft',
        forceSeparateButton: true,
        forcePseudoFullscreen: true,
        title: 'Полноэкранный режим',
        titleCancel: 'Выйти из полноэкранного режима',
      });
      this.fullscreenControl.addTo(this.myMap);
      // FIXME - unload event when unloading component
      this.$nextTick(() => {
        this.myMap.on('exitFullscreen', () => localStorage.setItem('fullScreenState', 'false'));
        this.myMap.on('enterFullscreen', () => localStorage.setItem('fullScreenState', 'true'));
      });
      if (localStorage.getItem('fullScreenState') === 'true') {
        this.myMap.toggleFullscreen();
      }
      this.myMap.on('enterFullscreen', () => {
        this.fullScreenStatus = true;
        this.hideToolbars();
      });
      this.myMap.on('exitFullscreen', () => {
        this.fullScreenStatus = false;
        this.showToolbars();
      });
    },
    hideToolbars() {
      // hide side bar with class .leaflet-sidebar-tabs if it exists
      // const sidebarTabs = document.querySelector('.leaflet-sidebar');
      // if (sidebarTabs) {
      //   sidebarTabs.style.display = 'none';
      // }
      const bars = document.querySelectorAll('.leaflet-bar');
      bars.forEach((bar) => {
        const barElement = bar;
        // skip bar if it contains an element with class .fullscrenn-buttob
        if (barElement.querySelector('.leaflet-control-zoom-fullscreen')) {
          return;
        }
        if (barElement.querySelector('.polyline-measure-unicode-icon')) {
          return;
        }
        if (barElement.querySelector('.leaflet-control-zoom-in')) {
          return;
        }
        if (barElement.querySelector('.leaflet-control-zoom-out')) {
          return;
        }
        // console.log(bar);
        barElement.style.display = 'none';
      });

      // const bars = document.querySelectorAll('.leaflet-bar');
      // bars.forEach((bar) => {
      //   const barElement = bar;
      //   barElement.style.display = 'none';
      // });
    },
    showToolbars() {
      // show side bar with class .leaflet-sidebar-tabs if it exists
      const sidebarTabs = document.querySelector('.leaflet-sidebar');
      if (sidebarTabs) {
        sidebarTabs.style.display = 'block';
      }
      // hide bars with class
      const bars = document.querySelectorAll('.leaflet-bar');
      bars.forEach((bar) => {
        const barElement = bar;
        barElement.style.display = 'block';
      });
    },
    initPolylinemeasure() {
      console.log('initPolylinemeasure');
      // FIXME: Errors in console: listener not found
      // add measure control
      const options = {
        position: 'topleft',
        unit: 'metres',
        clearMeasurementsOnStop: true,
      };
      // eslint-disable-next-line new-cap
      const measure = new window.L.control.polylineMeasure(options);
      measure.addTo(this.myMap);
    },

    initCopyCoordinatesControl() {
      // Adding leaflet-copy-coordinates-control
      this.copycoordinatesControl = new window.L.Control.Coordinates();
      // console.log(c);
      this.copycoordinatesControl.addTo(this.myMap);
      // FIXME - unload event when unloading component
      this.myMap.on('click', (e) => {
        this.copycoordinatesControl.setCoordinates(e);
      });
    },

    toggleControls() {
      let display;
      if (this.controlsVisible) {
        display = 'none';
        if (!this.fullScreenStatus) this.myMap.toggleFullscreen();
      } else {
        display = 'block';
        if (this.fullScreenStatus) this.myMap.toggleFullscreen();
      }
      const bars = document.querySelectorAll('.leaflet-control');
      bars.forEach((bar) => {
        const barElement = bar;
        barElement.style.display = display;
      });
      this.controlsVisible = !this.controlsVisible;
    },
    assignHotkeys() {
      hotkeys('f', (event) => {
        if (this.isTextFieldActive()) return;
        console.log('You pressed f!');
        event.preventDefault();
        this.myMap.toggleFullscreen();
      });
      hotkeys('shift+h', () => {
        if (this.isTextFieldActive()) return;
        console.log('You pressed shift+h!');
        this.toggleControls();
      });
    },
    deassignHotkeys() {
      hotkeys.unbind('f');
    },
  },
  computed: {
    myMap() {
      return this.leafletRef.leafletObject;
    },
  },
  beforeUnmount() {
    this.deassignHotkeys();
    if (this.fullscreenControl) {
      this.myMap.removeControl(this.fullscreenControl);
    }
    if (this.copycoordinatesControl) {
      this.myMap.removeControl(this.copycoordinatesControl);
    }
    if (this.bookmarksControl) {
      this.myMap.removeControl(this.bookmarksControl);
    }
  },
  mounted() {
    this.initFullScreen();
    if (myhelper.isResearcher()) {
      this.initBookmarks();
    }
    // this.initPolylinemeasure();
    this.initCopyCoordinatesControl();
    this.assignHotkeys();
    // console.log(toRaw(this.leafletRef.ready));
    // console.log(toRaw(this.myMap));
  },
};
</script>

<style>
.leaflet-pseudo-fullscreen {
  z-index: 2001;
}
.leaflet-control-zoom-fullscreen {
  border-radius: 0px;
}
.leaflet-container .leaflet-control-container .leaflet-control > a {
  border-radius: 0px;
}
.leaflet-control-layers {
  border-radius: 0px;
}

.leaflet-control .leaflet-control-layers-toggle {
  background-image: url('@/assets/icons/layer-group-solid.png');
  background-size: 18px;
}
.leaflet-bottom.leaflet-left {
  z-index: 800;
}

.leaflet-bookmarks-to-right {
  width: 14px;
  height: 14px;
}
</style>
