// eslint-disable-next-line no-unused-vars
import { createApp, nextTick } from 'vue';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import * as Sentry from '@sentry/vue';
// eslint-disable-next-line no-unused-vars
import posthog from 'posthog-js';
import VueGtag from 'vue-gtag';
import myhelper from '@/plugins/myhelper';
// import posthogPlugin from './plugins/analytics/posthog';
import App from './App.vue';
import 'element-plus/dist/index.css';
import router from './router';
import store from './store';

import './plugins/elementplus';

const app = createApp(App).use(store).use(router).use(ElementPlus);
// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
  // console.log(key);
}

app.config.globalProperties.$caemap = {};
store.$caemap = app.config.globalProperties.$caemap;
app.config.globalProperties.$docs = {
  map: process.env.VUE_APP_DOCS_MAP,
  settings: process.env.VUE_APP_DOCS_SETTINGS,
  sidebar: process.env.VUE_APP_DOCS_SIDEBAR,
  bookreader: process.env.VUE_APP_DOCS_BOOKREADER,
};
app.config.globalProperties.$mdpages = {
  aboutcae: process.env.VUE_APP_MDPAGE_ABOUTCAE,
  about: process.env.VUE_APP_MDPAGE_ABOUT,
};
// VUE_APP_MODIFIER
app.config.globalProperties.$appmodifier = process.env.VUE_APP_MODIFIER;
//
// set global variable for process.env.VUE_APP_MY_VARIABLE

if (process.env.NODE_ENV === 'development') {
  app.config.performance = true;
}
myhelper.fetchUserCountry().then((currentCountry) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('currentCountry', currentCountry);
  }
  if (currentCountry === 'CN') return;
  app.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GA_ID,
    },
  });
  // app.use(posthogPlugin); // install the plugin
});

// eslint-disable-next-line no-unused-vars
const posthogApiHost =
  process.env.VUE_APP_MODIFIER === 'prod' ? 'https://w.caemap.com' : 'https://w.caemap.online';
if (process.env.NODE_ENV !== 'development') {
  app.config.globalProperties.$posthog = posthog.init(process.env.VUE_APP_POSTHOG_API_KEY, {
    api_host: posthogApiHost,
    // person_profiles: 'identified_only',
    capture_pageview: false,
  });
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.captureConsoleIntegration({
        // ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error'],
      }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
      // [
      //   posthog.sentryIntegration({
      //     organization: '4507792021454848',
      //     projectId: '4507792023486544',
      //     severityAllowList: ['error', 'info'], // optional: here is set to handle captureMessage (info) and captureException (error)
      //   }),
      // ],
    ],
    // Tracing
    tracesSampleRate: 0.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/caemap\.com\/api/,
      /^https:\/\/dev\.caemap\.com\/api/,
    ],
    tunnel: `${process.env.VUE_APP_API_URL}/sentry-tunnel`,
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.2, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  router.afterEach((to, from, failure) => {
    if (!failure && from.path !== to.path) {
      nextTick(() => {
        app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
      });
    }
  });
}

router.afterEach((to) => {
  if (to.meta?.title) {
    document.title = to.meta.title;
  }
});

router.isReady().then(() => {
  const { magicLink } = router.currentRoute.value.query;
  // http://localhost:8085/?magicLink=ResearcherMode
  if (magicLink === 'ResearcherMode') {
    router.replace({ query: {} });
    console.warn('Magic Link is set');
    localStorage.setItem('authRole', 'researcher');
  }
});
app.mount('#app');
