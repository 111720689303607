<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="component-sidebar">
    <div
      :class="`leaflet-sidebar-control-buttons sidebar-${sidebarStatus}`"
      @click="onClickToggleSidebar"
    >
      <!-- <i class="fas fa-caret-left"></i> -->
      <span class="tabicon"></span>
    </div>
    <div id="sidebar" class="leaflet-sidebar">
      <!-- nav tabs -->
      <div class="leaflet-sidebar-tabs">
        <!-- top aligned tabs -->
        <ul role="tablist">
          <li class="active">
            <a href="#home" role="tab" style=""><i class="fa fa-bars active"></i>Place details</a>
          </li>
          <!--
          <li class="disabled" title="Messages">
            <a class="" href="#mail" role="tab"><i class="fa fa-envelope"></i></a>
          </li> -->
          <li v-if="isAdmin">
            <a href="#profile" role="tab"><i class="fa fa-user"></i></a>
          </li>
          <li v-if="isResearcher">
            <a href="#measure" role="tab"><i class="fa fa-ruler"></i></a>
          </li>
          <li class="" title="tracks" v-if="isResearcher">
            <a class="" href="#tracks" role="tab" style=""><i class="fa fa-road"></i>Tracks</a>
          </li>
        </ul>

        <!-- bottom aligned tabs -->
        <ul role="tablist">
          <li class="" title="Settings">
            <a class="" href="#settings" role="tab" style=""><i class="fa fa-cog"></i>Settings</a>
          </li>
        </ul>
      </div>

      <!-- panel content -->
      <div class="leaflet-sidebar-content">
        <div class="leaflet-sidebar-pane" id="profile" v-if="isAdmin">
          <h2 class="leaflet-sidebar-header">
            Adm<span class="leaflet-sidebar-close"><i class="fas fa-caret-right"></i></span>
          </h2>
          Admin: {{ isAdmin }}
          <el-row v-if="isAdmin">
            <el-col :span="24">
              <h3><router-link to="/admin">Admin</router-link></h3>
              <h3>
                <router-link to="/placeslist">PlacesList</router-link>
              </h3>
              <h3>
                <router-link to="/admin/images">Images</router-link>
              </h3>
              <h3>
                <router-link to="/bookslist">BooksList</router-link>
              </h3>
              <p>
                <el-button @click="fetchData" title="Refresh data from server" v-if="isAdmin"
                  ><el-icon><Refresh /></el-icon
                ></el-button>
              </p>
            </el-col>
          </el-row>
        </div>
        <div class="leaflet-sidebar-pane" id="measure" v-if="isResearcher">
          <h1 class="leaflet-sidebar-header">
            Измерить дистанцию на маршруте<span class="leaflet-sidebar-close"
              ><i class="fas fa-caret-right"></i
            ></span>
          </h1>

          <LMapMeasure />
        </div>
        <div class="leaflet-sidebar-pane active" id="home">
          <h1 class="leaflet-sidebar-header">
            Информация о месте
            <!-- Place info -->
            <span class="leaflet-sidebar-close"><i class="fa fa-caret-right"></i></span>
            <span class="leaflet-sidebar-infobutton"
              ><a target="_blank" :href="$docs.sidebar"><i class="fa fa-question-circle"></i></a
            ></span>
          </h1>

          <div v-if="selectedPlace === 0">
            <p>Ничего не выбрано</p>
          </div>
          <div v-else>
            <el-row align="middle">
              <el-col :span="4">
                <el-button type="default" @click="onClickPrev" v-if="prevButtonEnabled()">
                  <el-icon :size="20"> <ArrowLeftBold /></el-icon>
                </el-button>

                <!-- <el-icon :size="20"><ArrowLeftBold /></el-icon> -->
              </el-col>

              <el-col :span="16">
                <h2 id="sidebar-title">
                  {{ mainTitle }}
                  <router-link
                    :to="`/place/edit/${curPlace.place_id}`"
                    class="link-type place-hover-link"
                    v-if="isAdmin"
                    ><el-icon><Edit /></el-icon>
                  </router-link>
                  <el-button
                    @click="copyCoordtoClipboard(curPlace.latitude, curPlace.longitude)"
                    class="link-type place-hover-link"
                    link
                    v-if="isAdmin"
                    ><el-icon title="Copy coordinated"><CopyDocument /></el-icon
                    ><el-icon title="Copy coordinated"><LocationFilled /></el-icon
                  ></el-button>

                  <span class="title-placeid" v-if="mapOptions.showDebugInfo">
                    <br />{{ curPlace.place_id }}
                  </span>
                </h2>
              </el-col>
              <el-col :span="4" style="text-align: right">
                <el-button type="default" @click="onClickNext" v-if="nextButtonEnabled()">
                  <el-icon :size="20"><ArrowRightBold /></el-icon>
                </el-button>
              </el-col>
            </el-row>
            <div v-if="mapOptions.editorPlaceQuickEdit" style="border-style: dotted">
              <el-row>
                <el-col :span="14">
                  <el-form-item label="MinZoom">
                    <el-input-number
                      v-model="curPlace.minzoom"
                      :step="1"
                      @change="onMinzoomChange"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="10" class="adm-copy-helper">
                  <el-button @click="copyBookTextHelper('p')"> p </el-button>
                  <el-button @click="copyBookTextHelper('span')"> span </el-button>
                  <el-button @click="copyBookTextHelper('mapcarta')"> mapcarta </el-button>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="Tooltip position">
                    <el-radio-group
                      v-model="curPlace.tooltip_position"
                      class="ml-4"
                      size="small"
                      @change="onTtposChange"
                    >
                      <el-radio-button value="left">Left</el-radio-button>
                      <el-radio-button value="right">Right</el-radio-button>
                      <el-radio-button value="top">Top</el-radio-button>
                      <el-radio-button value="bottom">Bottom</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="Accuracy Level">
                    <el-radio-group
                      v-model="curPlace.accuracy_level"
                      class="ml-4"
                      size="small"
                      @change="onAccLvlChange"
                    >
                      <el-radio-button
                        v-for="item in getAccuracyOptions"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.value }}</el-radio-button
                      >
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24" v-if="curPlace.content_todo"
                  ><b>Content ToDo</b>: {{ curPlace.content_todo }}
                </el-col>
                <el-col :span="24" v-if="curPlace.internal_notes">
                  <div
                    v-html="compiledMarkdown('***Int. notes***: ' + curPlace.internal_notes)"
                  ></div>
                </el-col>
                <el-col :span="24"> </el-col>
                <el-col :span="24"> </el-col>
                <el-col :span="24"> </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-checkbox
                    v-model="curPlace.path_ref_req"
                    label="Требуется уточнение маршрута  "
                    size="large"
                    @change="onPathRefReqChange"
                    style="padding: 0px 20px 0px 0px"
                  />
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="11">
                  <el-checkbox
                    v-model="curPlace.hide_distance"
                    label="Hide distance"
                    size="large"
                    @change="onHideDistanceChange"
                  />
                </el-col>
              </el-row>
            </div>
            <div v-if="isMissingDayFromPrevStop() && isAdmin" class="sidebar-placeinfo-main">
              <p style="color: #f00; font-weight: bold">Пропущен день от предыдущей стоянки</p>
            </div>

            <div v-if="curPlaceDateRange">
              <p id="sidebar-eventdate">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="accLevel2Tooltip(curPlace.accuracy_level)"
                  placement="top"
                  v-if="curPlace.accuracy_level !== 'unknown'"
                >
                  <img
                    :src="accLevel2Image(curPlace.accuracy_level)"
                    alt="FIXME"
                    class="acclevel-sidebar"
                    :title="accLevel2Tooltip(curPlace.accuracy_level)"
                    v-if="curPlace.accuracy_level !== 'unknown'"
                  />
                </el-tooltip>

                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="faClassInfo['tooltip']"
                  placement="top"
                  v-if="faClassInfo['tooltip']"
                >
                  <i :class="faClassInfo['icon']" :title="faClassInfo['tooltip']"></i>
                </el-tooltip>
                <i v-else :class="faClassInfo['icon']" :title="faClassInfo['tooltip']"></i>

                {{ curPlaceDateRange }}
                <el-popover
                  ref="distancePopoverRef"
                  placement="top-start"
                  title=""
                  v-if="curPlace.date_acc > 0"
                  :width="180"
                  trigger="click"
                  popper-class="dateacc-popover"
                >
                  <template #default> Даты указаны примерно и могут отличаться </template>
                  <template #reference>
                    <a href="#" @click.prevent>( ? )</a>
                  </template>
                </el-popover>

                <span v-if="curPlace.place_type == 'ontheway'"> (в пути) </span>

                <el-tooltip
                  v-if="directionsLink()"
                  class="box-item"
                  effect="dark"
                  content="Проложить маршрут"
                  placement="top"
                >
                  <a target="_blank" :href="directionsLink()" class="directions-link">
                    <i class="fa fa-route"></i>
                  </a>
                </el-tooltip>
              </p>
              <hr />
            </div>

            <div class="sidebar-placeinfo-main" v-if="curPlace.title_modern">
              <p>
                <b>{{ modernTitleText }}</b> {{ modernTitle }}
              </p>
            </div>
            <div class="sidebar-placeinfo-main" v-if="diaryAltTitles">
              <p>
                <b>{{ diaryAltTitles.name }}:</b> {{ diaryAltTitles.titles }}
              </p>
            </div>
            <div
              v-if="getDistanceFromPrevStopInfo"
              class="sidebar-placeinfo-main"
              style="margin-top: -9px"
            >
              <p>
                <b>{{ getDistanceFromPrevStopInfo.passedtext }}: </b>

                <el-popover
                  ref="distancePopoverRef"
                  placement="top-start"
                  title="Расстояние"
                  :width="450"
                  effect="dark"
                  trigger="click"
                  popper-class="distance-popover"
                  :auto-close="3000"
                >
                  <template #default>
                    От <b>"{{ getDistanceFromPrevStopInfo.prevPlace.title_ru }}"</b> до
                    <b>"{{ curPlace.title_ru }}"</b>
                  </template>
                  <template #reference>
                    <a href="#" class="distance-link" @click.prevent
                      >{{ getDistanceFromPrevStopInfo.distance }} км</a
                    >
                  </template>
                </el-popover>
                <span class="sidebar-place-transport" v-if="curPlace.go_by === prevPlace.go_by">
                  <span class="sidebar-place-goby-emoji">
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="goByInfo(curPlace.go_by).tooltip"
                      placement="top"
                    >
                      <span v-html="goByInfo(curPlace.go_by).icon" />
                    </el-tooltip>
                  </span>
                </span>
                <span class="sidebar-place-transport" v-else>
                  <span class="sidebar-place-goby-emoji">
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="goByInfo(prevPlace.go_by).tooltip"
                      placement="top"
                    >
                      <span v-html="goByInfo(prevPlace.go_by).icon" />
                    </el-tooltip>
                  </span>
                  <span class="sidebar-transport-change"
                    ><i class="fas fa-long-arrow-alt-right"></i
                  ></span>
                  <span class="sidebar-place-goby-emoji">
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="goByInfo(curPlace.go_by).tooltip"
                      placement="top"
                    >
                      <span v-html="goByInfo(curPlace.go_by).icon" />
                    </el-tooltip>
                  </span>
                </span>
              </p>
            </div>
            <div v-if="curPlace.overnight_place" class="sidebar-placeinfo-main">
              <div
                v-html="compiledMarkdown(`**${overnight_text}**: ${curPlace.overnight_place}`)"
              ></div>
            </div>
            <div v-if="curPlace.location_reference" class="sidebar-placeinfo-main">
              <!-- Location reference  -->
              <div v-html="compiledMarkdown('**Ориентир**: ' + curPlace.location_reference)"></div>
            </div>
            <!-- <div v-if="curPlace.loc_ref_req" class="sidebar-placeinfo-main">
              <p>
                <b>Место стоянки требует уточнения</b>
                <span v-if="curPlace.loc_ref_req_info">: {{ curPlace.loc_ref_req_info }}</span>
              </p>
            </div> -->
            <div v-if="curPlace.path_ref_req_info" class="sidebar-placeinfo-main">
              <p>
                <b>Этот участок маршрута требует дополнительных исследований</b>
                <span v-if="curPlace.path_ref_req_info">: {{ curPlace.path_ref_req_info }}</span>
              </p>
            </div>

            <div v-if="curPlace.traveler_todo" class="sidebar-placeinfo-main">
              <div v-html="compiledMarkdown('**Уточнить**: ' + curPlace.traveler_todo)"></div>
            </div>
            <div v-if="curPlace.notes" class="sidebar-placeinfo-main sidebar-notes">
              <div v-html="compiledMarkdown(curPlace.notes)"></div>
            </div>
            <div v-if="false">
              <div v-if="curPlace.path_george_diary" class="book-text">
                <hr />
                <b>Ю.Н. Рерих:</b>
                <div v-html="compiledMarkdown(curPlace.path_george_diary)"></div>
              </div>
              <div v-if="curPlace.path_nicholas_diary" class="book-text">
                <hr />
                <b>Н.К. Рерих:</b>
                <div v-html="compiledMarkdown(curPlace.path_nicholas_diary)"></div>
              </div>
              <div v-if="curPlace.path_fosdick_diary" class="book-text">
                <b>З.Г. Фосдик:</b>
                <div v-html="compiledMarkdown(curPlace.path_fosdick_diary)"></div>
              </div>
              <div v-if="curPlace.path_ryabinin_diary" class="book-text">
                <b>Рябинин:</b>
                <div v-html="compiledMarkdown(curPlace.path_ryabinin_diary)"></div>
              </div>
            </div>
            <PlaceBookText :placeId="this.selectedPlace" />
          </div>

          <div class="placeimages sidebar-placeinfo-main">
            <div style="margin-bottom: 10px" v-if="imagesForPlace.length > 0">
              <hr />
              <h4>
                Фотографии и картины
                <a
                  href="#"
                  @click.prevent="mapOptions.showAllPhotos = !mapOptions.showAllPhotos"
                  v-if="photoCountPerVisibility.low > 0"
                  class="photo-counter"
                >
                  <span v-if="mapOptions.showAllPhotos">
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="Показать избранные фотографии"
                      placement="top"
                    >
                      ({{ photoCountPerVisibility.total }}/{{ photoCountPerVisibility.total }})
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="Показать все фотографии"
                      placement="top"
                    >
                      ({{ photoCountPerVisibility.high }}/{{ photoCountPerVisibility.total }})
                    </el-tooltip>
                  </span>
                </a>
              </h4>
              <!-- <div v-if="photoCountPerVisibility.low > 0">
                <el-checkbox
                  v-model="mapOptions.showAllPhotos"
                  label="Отображать все фотографии"
                  size="large"
                />
              </div> -->
            </div>
            <!-- <hr class="sidebar-image-divider" /> -->

            <div
              v-for="(imageGroup, index) in splittedImageForPlace"
              :key="index"
              class="sidebar-image-group"
            >
              <el-row :gutter="0">
                <el-col
                  :span="12"
                  v-for="item in imageGroup"
                  :key="item.image_id"
                  class="gallery-div"
                >
                  <div class="image-div">
                    <a
                      className="gallery-item2"
                      :data-src="item.url"
                      :data-sub-html="item.subHtml"
                      @click="showGallery(item.index)"
                      :title="item.title_ru"
                    >
                      <!-- <el-image
                        :src="item.thumb"
                        :alt="item.title_ru"
                        :lazy="true"
                        scroll-container=".leaflet-sidebar-content"
                      /> -->
                      <img className="img-responsive" :src="item.thumb" :alt="item.title_ru" />
                      <div
                        v-if="item.latitude && item.longitude"
                        class="gallery-item-hasgpscoord"
                        @click.prevent.stop="mapSetViewForImage(item)"
                      >
                        <i class="fa fa-map-marker-alt"></i>
                      </div>

                      <div class="play-button-overlay" v-if="item.image_type === 'video'">
                        <button class="play-button">▶</button>
                      </div>
                    </a>
                  </div>
                  <div class="image-meta">
                    <span>{{ item.title_ru }} </span>
                  </div>
                </el-col>
              </el-row>
              <hr class="sidebar-image-divider" />
            </div>

            <div
              v-for="image in imagesForPlace"
              :key="image.id"
              style="padding-bottom: 20px; display: none"
            >
              <!-- <el-image
                style="height: 100px; vertical-align: middle"
                :key="image.image_id"
                :src="image.url"
                :fit="fit"
                lazy
              /> -->
              <el-card
                :body-style="{ padding: '0px' }"
                style="border: 2px solid rgb(255 255 255); box-shadow: 0 0px 12px rgb(56 56 56)"
              >
                <!-- <img :src="image.url" class="image" alt="Prop" style="width: 100%" /> -->
                <el-image
                  :fit="fit"
                  :src="image.url"
                  :preview-src-list="previewSrcList"
                  style="height: 100%; vertical-align: middle"
                />
                <div style="padding: 8px 0 0 8px">
                  <span>
                    <b>{{ image.title_ru }}</b>
                    <time v-if="image.year" class="time">, {{ image.year }}</time>
                  </span>
                </div>
                <div style="padding: 0px 0 8px 8px">{{ image.author_ru }}</div>
              </el-card>

              <!-- <img :src="image.url" :alt="image.alt" /> -->
              <!-- {{ image.filename }} -->
            </div>
          </div>
        </div>

        <!-- <div class="leaflet-sidebar-pane" id="messages">
          <h1 class="leaflet-sidebar-header">
            Messages<span class="leaflet-sidebar-close"><i class="fa fa-caret-right"></i></span>
          </h1>
        </div> -->

        <div class="leaflet-sidebar-pane" id="settings">
          <h1 class="leaflet-sidebar-header">
            Настройки<span class="leaflet-sidebar-close"><i class="fas fa-caret-right"></i></span>
            <span class="leaflet-sidebar-infobutton"
              ><a target="_blank" :href="$docs.settings"><i class="fa fa-question-circle"></i></a
            ></span>
          </h1>
          <AppSettings />
        </div>
        <div class="leaflet-sidebar-pane" id="tracks" v-if="isResearcher">
          <h1 class="leaflet-sidebar-header">
            Загрузка треков на карту<span class="leaflet-sidebar-close"
              ><i class="fas fa-caret-right"></i
            ></span>
          </h1>
          <AppTracksManage />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import 'leaflet-sidebar-v2';
import 'leaflet-sidebar-v2/css/leaflet-sidebar.css';
import gallery from '@/plugins/gallery';
import myhelper from '@/plugins/myhelper';
import TruncateReadMore from '@/components/TruncateReadMore.vue';
import LMapMeasure from '@/components/LMapMeasure.vue';
import PlaceBookText from '@/components/PlaceBookText.vue';
import AppSettings from '@/components/AppSettings.vue';
import AppTracksManage from '@/components/AppTracksManage.vue';
import eventBus from '@/services/eventBus';

export default {
  name: 'LMapSidebar',
  props: {
    leafletRef: { type: Object, required: true },
    selectedPlace: { type: Number, required: true },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TruncateReadMore,
    LMapMeasure,
    PlaceBookText,
    AppSettings,
    AppTracksManage,
  },
  inject: {
    sidebarcontrol: { default: '' },
    mapOptions: { default: {} },
    tracksList: { default: [] },
  },
  data() {
    return {
      sidebar: '',
      sidebarStatus: 'closed',
      allBooksTextByDate: [],
      curPlace: null,
    };
  },
  methods: {
    goByEmoji: myhelper.goByEmoji,
    goByInfo: myhelper.goByInfo,
    accLevel2Image: myhelper.accLevel2Image,
    copyCoordtoClipboard: myhelper.copyCoordtoClipboard,
    // eslint-disable-next-line no-unused-vars
    accLevel2Tooltip(placeAccLevel) {
      // const tt = {
      //   low: 'Low',
      //   medium: 'Medium',
      //   high: 'High',
      //   exact: 'Exact',
      // };
      return 'Точность соответствия маршруту';
      // FIXME - check if acclevel exists
      // return tt[placeAccLevel];
    },
    fetchData() {
      this.$store.dispatch('places/fetchPlaces');
    },

    ...mapGetters('places', [
      'mapMarkersList',
      'isInitialized',
      'placeByID',
      'getPrevStopPlace',
      'getPrevPlace',
      'getNextPlace',
      'getPlacesListFromRange',
    ]),
    ...mapGetters('images', ['getImageByPlaceId']),
    ...mapActions('books', ['fetchAllBooksTextByDate']),
    ...mapActions('places', ['updatePlace']),

    copyBookTextHelper(tag) {
      let text = '';
      if (tag === 'span') {
        text = `<span data-text4placeid='${this.selectedPlace}' class='ellipsis-before ellipsis-after'></span>`;
      }
      if (tag === 'p') {
        text = ` data-text4placeid='${this.selectedPlace}'  class='ellipsis-before ellipsis-after'`;
      }
      if (tag === 'mapcarta') {
        const zoom = this.myMap.getZoom();
        const center = this.myMap.getCenter();
        console.log(center);
        text = `globespin=false; map.flyTo({ center: [${center.lng},${center.lat}], zoom: ${zoom}, }); `;
      }
      if (text) {
        myhelper.copyToClipboard(text);
      }
    },
    compiledMarkdown: myhelper.compiledMarkdown,
    openSideBar() {
      this.sidebar.open('home');
    },
    setSelectedPlace() {
      if (this.selectedPlace > 0) {
        // console.log('setSelectedPlace', this.selectedPlace);
        this.curPlace = this.placeByID()(this.selectedPlace);
        try {
          document.getElementsByClassName('leaflet-sidebar-content')[0].scrollTop = 0;
          // eslint-disable-next-line no-empty
        } catch (e) {}

        // console.log(this.splittedImageForPlace);
        if (this.$posthog) {
          this.$posthog.capture('openSideBarPlace', {
            placeId: this.selectedPlace,
            title: this.curPlace.title_ru,
          });
        }
      }
    },

    addSideBarOnMap() {
      this.sidebar = window.L.control.sidebar({
        container: 'sidebar',
        closeButton: true,
        autopan: false,
        position: 'right',
      });
      this.sidebar.on('closing', () => {
        // this.$emit('closingSidebar');
        this.sidebarStatus = 'closed';
      });
      this.sidebar.on('opening', () => {
        this.sidebarStatus = 'opened';
      });
      this.myMap.addControl(this.sidebar);
      this.sidebar.close();
      if (typeof this.sidebarcontrol === 'object') {
        this.sidebarcontrol.control = this.sidebar;
        this.sidebarcontrol.open = this.openSideBar;
      }
      // if (this.selectedPlace) {
      //   this.sidebarcontrol.open();
      // }
    },
    showGallery(slidenum) {
      gallery.show(
        this.imagesForPlace,
        slidenum,
        this.mapOptions.showDebugInfo,
        this.mapOptions.animation,
      );
    },
    prevButtonEnabled() {
      const place = this.getPrevPlace()(this.curPlace.seqid);
      if (place && place.ispathpart) {
        return true;
      }
      return false;
    },
    nextButtonEnabled() {
      const place = this.getNextPlace()(this.curPlace.seqid);
      if (place && place.ispathpart) {
        return true;
      }
      return false;
    },
    onClickPrev() {
      this.$emit('prevPlace');
    },
    onClickNext() {
      this.$emit('nextPlace');
    },
    onMinzoomChange(value) {
      // console.log(value);
      // console.log(this.$store.getters['places/placeByID'](123).minzoom);
      this.updatePlace({ placeId: this.selectedPlace, place: { minzoom: value } });
      // this.$emit('minzoomChange', value);
    },
    onTtposChange(value) {
      // console.log(value);
      this.updatePlace({ placeId: this.selectedPlace, place: { tooltip_position: value } });
    },
    onAccLvlChange(value) {
      // console.log(value);
      this.updatePlace({ placeId: this.selectedPlace, place: { accuracy_level: value } });
    },
    onPathRefReqChange(value) {
      // console.log(value);
      this.updatePlace({ placeId: this.selectedPlace, place: { path_ref_req: value } });
    },
    onHideDistanceChange(value) {
      // console.log(value);
      this.updatePlace({ placeId: this.selectedPlace, place: { hide_distance: value } });
    },
    onClickToggleSidebar() {
      if (this.sidebarStatus === 'closed') {
        this.sidebar.open('home');
        return;
      }
      this.sidebar.close();
    },
    isMissingDayFromPrevStop() {
      if (!this.curPlace.ispathpart) return false;
      const prevStop = this.getPrevStopPlace()(this.curPlace.seqid);
      if (!prevStop) return false;
      if (prevStop.enddate === this.curPlace.date) {
        return false;
      }
      if (this.curPlace.date_format !== 'YYYY-MM-DD' || prevStop.date_format !== 'YYYY-MM-DD') {
        return false;
      }
      const t = ['train', 'steamship'];
      if (t.includes(this.curPlace.go_by) || t.includes(prevStop.go_by)) {
        return false;
      }

      return true;
    },
    isAddDateRangeToDistances(prevStop) {
      if (prevStop.enddate === this.curPlace.enddate) {
        return false;
      }
      if (this.curPlace.date === this.curPlace.enddate) {
        return true;
      }
      if (myhelper.DaysBetweenDates(prevStop.enddate, this.curPlace.date) > 0) {
        return true;
      }
      return false;
    },
    isSettingsOpened() {
      const settingsEl = document.getElementById('settings');
      const sidebarEl = document.getElementById('sidebar');
      return settingsEl.classList.contains('active') && !sidebarEl.classList.contains('collapsed');
    },
    openSettings() {
      if (this.isSettingsOpened()) {
        this.sidebar.close();
      } else {
        this.sidebar.open('settings');
      }
    },
    mapSetViewForImage(image) {
      try {
        // const zoom = image.minzoom > 0 ? image.minzoom : 10;
        const zoom = 18;
        this.myMap.setView([image.latitude, image.longitude], zoom);
      } catch (error) {
        console.error('mapSetViewForImage', error);
      }
    },
    directionsLink() {
      if (this.curPlace && this.curPlace.latitude && this.curPlace.longitude) {
        const lat = this.curPlace.latitude;
        const lng = this.curPlace.longitude;
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      }
      return null;
    },
    showDirections() {
      if (this.curPlace && this.curPlace.latitude && this.curPlace.longitude) {
        const lat = this.curPlace.latitude;
        const lng = this.curPlace.longitude;

        // Option 1: Open Google Maps in a new tab
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        window.open(googleMapsUrl, '_blank');

        // Option 2: You could also emit an event to handle this in a parent component
        // this.$emit('show-directions', { lat, lng });

        if (this.$posthog) {
          this.$posthog.capture('showDirections', {
            placeId: this.selectedPlace,
            title: this.curPlace.title_ru,
          });
        }
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    selectedPlace() {
      this.setSelectedPlace();
    },
  },

  computed: {
    photoCountPerVisibility() {
      // return hash with count of highlighted  photos and others
      const r = { high: 0, low: 0, total: 0 };
      this.getImageByPlaceId()(this.selectedPlace).forEach((image) => {
        if (!image.image_enabled) return;
        r.total += 1;
        if (image.visibility_level !== 'high') {
          r.low += 1;
        } else {
          r.high += 1;
        }
      });
      return r;
    },
    isNonHighlightedImageExist() {
      return this.getImageByPlaceId()(this.selectedPlace).some((image) => {
        return image.image_enabled && image.visibility_level !== 'high';
      });
    },
    getAccuracyOptions: myhelper.getAccuracyOptions,
    isAdmin: myhelper.isAdmin,
    isResearcher: myhelper.isResearcher,

    modernTitle() {
      // replace comma with comma and space
      return this.curPlace.title_modern.replace(/;/g, ', ');
    },
    modernTitleText() {
      if (this.curPlace.title_modern.includes(';')) {
        return 'Cовременные названия:';
      }
      return 'Современное название:';
    },
    mainTitle() {
      return myhelper.getPlaceTitle(this.curPlace, false);
    },
    diaryAltTitles() {
      const altTitles = myhelper.getPlaceAltTitles(this.curPlace);
      if (altTitles.length < 1) return null;
      const name = altTitles.length > 1 ? 'Другие названия' : 'Другое название';
      return { titles: altTitles.join(', '), name };
    },
    prevPlace() {
      return this.getPrevPlace()(this.curPlace.seqid);
    },

    faClassInfo() {
      // return { 'tooltip': <tooltip>, 'iconclass': <icon> }
      if (!this.curPlace.enddate || this.curPlace.date_format !== 'YYYY-MM-DD') {
        return { tooltip: '', icon: 'fa fa-calendar-alt' };
      }
      if (myhelper.isPlaceStayedLong(this.curPlace)) {
        return { tooltip: 'Стоянка более 5 дней', icon: 'fa fa-home' };
      }
      return this.curPlace.date === this.curPlace.enddate
        ? { tooltip: 'Транзитная точка', icon: 'fa fa-chevron-circle-right' }
        : { tooltip: 'Стоянка менее 5 дней', icon: 'fa fa-campground' };
    },
    getDistanceFromPrevStopInfo() {
      if (!this.curPlace.ispathpart || this.curPlace.hide_distance) {
        return null;
      }

      const prevStop = this.getPrevStopPlace()(this.curPlace.seqid);
      const prevPlace = this.getPrevPlace()(this.curPlace.seqid);
      if (
        !prevStop ||
        !prevStop.latitude ||
        !prevStop.longitude ||
        this.curPlace.date_format !== 'YYYY-MM-DD' ||
        prevStop.date_format !== 'YYYY-MM-DD'
      ) {
        return null;
      }
      // if (myhelper.DaysBetweenDates(this.curPlace.date, this.curPlace.enddate) > 3) {
      //   return null;
      // }
      // if (prevPlace.go_by === 'train' || prevPlace.go_by === 'steamship') {
      //   return null;
      // }
      // if (prevPlace.minzoom <= 0) {
      //   return null;
      // }
      // if (this.curPlace.minzoom <= 0) {
      //   return null;
      // }
      let passedText = 'Пройдено за день';
      if (this.curPlace.date === this.curPlace.enddate) {
        passedText = 'Пройдено от прошлой стоянки';
      }
      if (prevPlace.go_by === 'train') {
        passedText = 'Пройдено на поезде';
      }
      if (prevPlace.go_by === 'steamship') {
        passedText = 'Пройдено на пароходе';
      }

      if (this.isAddDateRangeToDistances(prevStop)) {
        const dateRange = myhelper.humanizeDateRange(
          prevStop.enddate,
          this.curPlace.date,
          this.curPlace.date_format,
        );
        if (dateRange) {
          passedText += ` (${dateRange})`;
        }
      }

      const placesList = this.getPlacesListFromRange()(prevStop.place_id, this.curPlace.place_id);
      const polypath = myhelper.getPolyPathFromPlaces(placesList, false);
      const distance = myhelper.getPathDistance(this.myMap, polypath);
      return {
        prevPlace: prevStop,
        distance: (distance / 1000).toFixed(1),
        passedtext: passedText,
      };
    },
    overnight_text() {
      if (myhelper.DaysBetweenDates(this.curPlace.date, this.curPlace.enddate) > 3) {
        return 'Место проживания';
      }
      return 'Место ночевки';
    },

    fit() {
      return 'fill';
    },
    imagesForPlace() {
      if (!this.selectedPlace) return [];
      const r = this.getImageByPlaceId()(this.selectedPlace)
        .filter((image) => {
          if (!image.image_enabled) return false;
          if (this.mapOptions.showAllPhotos) return true;
          return image.visibility_level === 'high';
        })
        .map((image, index) => ({
          ...image,
          thumb:
            image.image_type === 'video'
              ? `https://img.youtube.com/vi/${image.video_id}/maxresdefault.jpg`
              : image.thumb,
          index,
        }));
      // console.log(r);
      return r;
    },
    splittedImageForPlace() {
      // split images array to multiple arrays by image_type using map_reduce
      const r = this.imagesForPlace.reduce((acc, image, idx) => {
        if (!acc[image.image_type]) {
          acc[image.image_type] = [];
        }
        acc[image.image_type].push({ ...image, slidenum: idx });
        return acc;
      }, {});
      // console.log(r);
      const arr = [];
      ['histexpphoto', 'histimage', 'painting', 'postcard', 'photo', 'video'].forEach((key) => {
        if (r[key]) {
          arr.push(r[key]);
        }
      });
      return arr;
    },
    previewSrcList() {
      return this.imagesForPlace.map((image) => image.url);
    },
    curPlaceDateRange() {
      return myhelper.humanizeDateRange(
        this.curPlace.date,
        this.curPlace.enddate,
        this.curPlace.date_format,
      );
    },
    myMap() {
      return this.leafletRef.leafletObject;
    },
  },
  beforeUnmount() {
    eventBus.off('open-sidebar', this.openSettings);
    this.myMap.removeControl(this.sidebar);
  },
  created() {
    this.setSelectedPlace();
  },

  mounted() {
    eventBus.on('open-sidebar', this.openSettings);
    if (this.isInitialized) {
      this.addSideBarOnMap();
    } else {
      const unwatch = this.$watch('isInitialized', () => {
        this.addSideBarOnMap();
        unwatch();
      });
    }
    this.$emit('ready');
  },
};
</script>

<style lang="scss">
@media (hover: none) and (pointer: coarse) {
  #sidebar .el-button:hover {
    background-color: var(--el-button-bg-color);
    border: var(--el-border);
    color: var(--el-button-text-color);
    outline: inherit;
  }
}

.leaflet-sidebar-infobutton {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 18px;
}
.leaflet-sidebar-infobutton a {
  color: #fff;
}
// using font-awesome
.gallery-item-hasgpscoord {
  position: absolute;
  bottom: 7px;
  right: 20px;
  // background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255 76 0);
  padding: 2px 4px;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 0 0 0 5px;
  transition: background-color 0.3s ease;
}
.gallery-item-hasgpscoord i {
  // color: rgb(0, 255, 165);
  width: 10px;
  height: 10px;
  position: relative;
  top: 0;
  right: 0;
  font-size: 20px;
}

#sidebar .photo-counter {
  text-decoration: none;
}
.adm-copy-helper .el-button {
  padding: 8px 8px;
}
.distance-link {
  text-decoration-style: dotted;
  text-underline-position: from-font;
}
.leaflet-bar {
  border-radius: 0px;
}
.leaflet-control-layers {
  border-radius: 2px;
}

.image-div {
  position: relative;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.component-sidebar .gallery-div .gallery-item2:hover .play-button-overlay {
  opacity: 1;
}
.gallery-div {
  padding: 4px;
}

.play-button {
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%; /* Make the button circular */
  width: 50px; /* Set width */
  height: 50px; /* Set height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3); /* Light background for contrast */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional shadow */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.play-button:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Change background on hover */
}

.sidebar-notes {
  text-align: justify;
}

.distance-popover .el-popover__title {
  text-align: center;
  font-weight: bold;
}
.dateacc-popover,
.distance-popover {
  text-align: center;
}

/* draw left/right button to openc close sidebar */
.leaflet-sidebar-control-buttons {
  display: inline-block;
  position: absolute;
  /* color: #f00; */
  background-color: #fff;
  z-index: 1999;
  top: 50%;
  margin-top: -23px;
  height: 46px;
  width: 23px;
  border-radius: 20px 0px 0px 20px;
  cursor: pointer;

  right: 1px;
  transition: all 500ms;
  box-shadow: 4px 4px 16px grey;
}

.sidebar-opened.leaflet-sidebar-control-buttons {
  right: 570px;
  transition: all 500ms;
  box-shadow: none;
}

.leaflet-sidebar-control-buttons .tabicon {
  display: block;
  top: 17px;
  border-top: 6px dashed transparent;
  border-bottom: 6px dashed transparent;
  position: absolute;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.sidebar-opened .tabicon {
  left: 9px;
  border-left: 8px solid #333;
}

.sidebar-closed .tabicon {
  left: 7px;
  border-right: 8px solid #333;
}

/* Hide tabs panel in the sidebar */
.collapsed.leaflet-sidebar {
  border: 0;
}

.leaflet-sidebar-right.collapsed ~ .leaflet-control-container .leaflet-right {
  right: 0;
}
.leaflet-sidebar.collapsed {
  width: 0px;
}
.leaflet-sidebar-header {
  background-color: #42a5f5;
  text-shadow: 1px 1px 2px #00000082;
  height: var(--app-menu-height);
  line-height: var(--app-menu-height);
}
.leaflet-sidebar-tabs > ul > li.active > a {
  background-color: #42a5f5;
}
.leaflet-sidebar-tabs > li,
.leaflet-sidebar-tabs > ul > li {
  height: var(--app-menu-height);
}
leaflet-sidebar-tabs > li > a,
.leaflet-sidebar-tabs > ul > li > a {
  line-height: var(--app-menu-height);
}

.placeimages h4 {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
}
.sidebar-image-group {
  margin-bottom: 15px;
}
hr.sidebar-image-divider {
  border: 0.5px solid #42a5f5;
  margin-top: 9px;
  width: 50%;
}
#sidebar-eventdate .acclevel-sidebar {
  margin-right: 5px;
  height: 13px;
  position: relative;
  bottom: -1px;
}
.sidebar-placeinfo-main {
  font-size: 14px;
  text-align: justify;
}
.sidebar-placeinfo-extra {
  font-size: 13px;
}

.sidebar-place-transport {
  padding-left: 10px;
  /* float: right; */
  font-style: normal;
  font-size: 1.9em;
  position: relative;
  bottom: -4px;
}

.sidebar-transport-change {
  padding: 0 5px;
  color: #000;
  position: relative;
  top: 2px;
}

.sidebar-place-goby-emoji {
  /* font-size: 1.6em; */
  font-size: 20px;
  line-height: 12px;
}
.sidebar-place-goby-emoji img {
  height: 28px;
  position: relative;
  top: 2.5px;
}
// .sidebar-place-goby-emoji img.horse {
// }

.leaflet-sidebar-pane.active > h1 {
  position: sticky;
  font-family: 'Noto Sans', sans-serif;
  top: 0;
  z-index: 2;
  font-size: 16px;
}

#sidebar-eventdate {
  font-family: sans-serif;
  font-style: italic;
  font-size: 0.9rem;
  clear: both;
}

.leaflet-sidebar-content {
  cursor: initial;
}

.place-hover-link {
  display: none;
}

h2:hover > .place-hover-link {
  display: inline;
}

.title-placeid {
  font-size: 0.8em;
  color: #666;
}

.leaflet-sidebar {
  z-index: 2000;
}

.component-sidebar .gallery-div .gallery-item {
  display: inline-block;
  vertical-align: text-top;
  width: 200px;
  /* padding: 5px; */
}

.image-div {
  aspect-ratio: 3/ 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 0;
}
.image-meta {
  padding: 0px 0 8px 8px;
  display: none;
}

.gallery-item2 .el-image img,
.img-responsive {
  width: 100%;
  height: 100%;
  padding: 0px;
  padding: 0px 2px;
  /* max-height: 120px; */
  cursor: zoom-in;
  box-sizing: border-box;
  object-fit: contain;
}

.leaflet-sidebar-pane {
  /*  FIXME - отступ сделан для Игоря */
  padding: 10px 25px 0px 20px;
}

#sidebar-title {
  text-align: center;
}

.leaflet-control .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
}

/* @media (min-width: 1620px) {
  .leaflet-sidebar {
    width: 558px;
    max-width: 531px;
  }
} */

@media (min-width: 1400px) {
  .leaflet-sidebar {
    width: 560px;
    max-width: 560px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .leaflet-sidebar {
    width: 355px;
    max-width: 355px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 0px;
    /* right: none; */
    /* transition: none; */
    box-shadow: 4px 4px 16px grey;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 317px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 402px;
  }
}
@media (min-width: 1200px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 472px;
  }
}
@media (min-width: 1400px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 572px;
  }
}
@media print {
  .leaflet-sidebar-control-buttons {
    display: none;
  }
}

@media (min-width: 1400px) {
  .leaflet-sidebar-right ~ .leaflet-control-container .leaflet-right {
    right: 570px;
  }
}

@media (max-height: 500px) {
  .leaflet-left .leaflet-control:not(.leaflet-control-horizontal-stack) {
    margin-left: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
  }
  .leaflet-top.leaflet-left .leaflet-control {
    margin-top: 0px;
    /* border-top-right-radius: 0; */
    /* border-top-left-radius: 0; */
    border-radius: 0 !important;
  }
  .leaflet-touch .leaflet-bar a:first-child,
  .leaflet-touch .leaflet-bar a:last-child {
    border-radius: 0 !important;
  }
  .leaflet-control-container .leaflet-control-layers.leaflet-control {
    margin-top: 20px;
  }
  .leaflet-sidebar.leaflet-touch .leaflet-control-layers {
    border: none;
    border-radius: 0 !important;
  }
  .leaflet-sidebar.leaflet-touch .leaflet-control {
    border: none;
    border-top: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
    border-radius: 0 !important;
  }
  .leaflet-control-container .leaflet-left .leaflet-bar {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
  }
  .leaflet-control-layers {
    border: none;
    border-radius: 0 !important;
  }
  .leaflet-control-layers {
    border-left: none !important;
    border-top: 1px solid #a3a3a3 !important;
    border-right: 1px solid #a3a3a3 !important;
    border-bottom: 1px solid #a3a3a3 !important;
  }
}

#sidebar-eventdate .directions-link {
  margin-left: 10px;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
  float: right;
}

#sidebar-eventdate .directions-link:hover {
  color: #1976d2;
}

#sidebar-eventdate .directions-link i {
  font-size: 1.1em;
}
</style>
